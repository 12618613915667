import React, { useEffect, useState } from 'react';

import Footer from '../components/footer';
import Header from '../components/header';
import SEO from '../components/seo';
import Content from '../components/content';

import { getNewsDetails } from '../api/server';

export default () => {
    const id = typeof window !== `undefined` ? window.location.hash.replace('#', '') : null;
    const [detail, setDetail] = useState({});

    const getDetails = async () => {
        if (!id) return;
        const _res = await getNewsDetails(id);
        if (_res) {
            const _data = _res.data;
            setDetail(_data);
        }
    };

    useEffect(() => {
        getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <section>
            <SEO title="新闻资讯" />
            <Header />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                    fontSize: '40px'
                }}
            >
                <h2>{detail.title}</h2>
            </div>
            <Content>
                <div
                    dangerouslySetInnerHTML={{ __html: detail.describe }}
                    style={{  margin: '40px' }}
                    className="content-item"
                ></div>
            </Content>

            <Footer />
        </section>
    );
};
